import {ContentBox, ContentMode} from '@hconnect/common/components/ContentBox'
import {DashboardLink} from '@hconnect/common/components/downtime'
import {EquipmentPerformanceResult} from '@hconnect/common/components/kpiPerformance/EquipmentPerformanceResult'
import {OperatingHoursLegendList} from '@hconnect/common/components/runningTimes'
import {useExpandedGroups} from '@hconnect/common/hooks/useExpandGroups'
import {EquipmentRunningTimes, RunningTime, RunningTimesEquipmentData} from '@hconnect/common/types'
import {Box} from '@mui/material'
import {FC, ReactNode} from 'react'
import {useTranslation} from 'react-i18next'

type RunningTimesSummaryProps = {
  contentMode: ContentMode
  isLoading?: boolean
  runningTimePerformances?: EquipmentRunningTimes[]
  getStackedBarChartComponent?: (
    equipment: RunningTimesEquipmentData,
    runningTimes: RunningTime[]
  ) => ReactNode
  plantId: string
}

export const RunningTimesSummary: FC<RunningTimesSummaryProps> = ({
  contentMode,
  isLoading,
  runningTimePerformances,
  getStackedBarChartComponent,
  plantId
}) => {
  const {t} = useTranslation()

  const equipmentIds: string[] =
    runningTimePerformances?.map((equipmentPerformance) => equipmentPerformance.equipment.id) || []

  const [expandedEquipments, toggleExpandedEquipments] = useExpandedGroups(
    equipmentIds,
    'summary-expanded-equipments'
  )

  return (
    <ContentBox
      title={
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          gap={1}
        >
          {t('summary.label.runningTimes')}
          <DashboardLink plantId={plantId} title={t('goToDashboard')} />
        </Box>
      }
      mode={contentMode}
      bodyWithPadding
      isLoading={isLoading}
      minHeight="450px"
      data-test-id="running-times-container"
    >
      <OperatingHoursLegendList />
      {runningTimePerformances?.map(
        (equipmentPerformanceData: EquipmentRunningTimes, index: number) => {
          return (
            <EquipmentPerformanceResult
              equipment={equipmentPerformanceData.equipment}
              runningTimePerformance={equipmentPerformanceData}
              key={equipmentPerformanceData.equipment.id}
              isExpanded={expandedEquipments.includes(equipmentPerformanceData.equipment.id)}
              getStackedBarChartComponent={getStackedBarChartComponent}
              isLast={runningTimePerformances?.length === index + 1}
              toggleExpandedEquipments={toggleExpandedEquipments}
            />
          )
        }
      )}
    </ContentBox>
  )
}
