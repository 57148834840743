import {ContentBox} from '@hconnect/common/components/ContentBox'
import {EventStatusLabel} from '@hconnect/common/components/eventProperties/EventStatusLabel'
import {TaskRepetitionLabel} from '@hconnect/common/components/shiftEventDetails/TaskRepetitionLabel'
import {
  PriorityTitle,
  EventProcessStageTitle
} from '@hconnect/common/components/shiftEventFormFields'
import {
  EventTypeLabel,
  getEquipmentLabelDetails
} from '@hconnect/common/components/shiftEventLabels'
import {Tag} from '@hconnect/common/components/Tag'
import {
  RootCauseFailureAnalyses,
  StatusWithNoneAndCancellationAndApproverAndWorkOrderStates,
  User
} from '@hconnect/common/types'
import {
  getShift,
  isStoppageTypeWithCode,
  isStoppageTypeWithReason,
  getStoppageDescription,
  getMaintainAnalysisUrl
} from '@hconnect/common/utils'
import {
  generateMarkdownOptions,
  getHighlightedTitle,
  getHighlightedTitleWithLink
} from '@hconnect/common/utils/highlightHelpers'
import {DateFormat} from '@hconnect/uikit'
import {MarkdownText} from '@hconnect/uikit/src/lib2'
import CloseIcon from '@mui/icons-material/Close'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import {Typography, Grid, Box, Link} from '@mui/material'
import {TFunction} from 'i18next'
import {cloneDeep, get, map, noop} from 'lodash'
import moment from 'moment-timezone'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {isWorkOrderStatus} from '../common/utils/eventStatus'
import {
  isIdea,
  isInformation,
  isParameterChange,
  isStoppage,
  isTask
} from '../common/utils/eventType'
import {EventInfo} from '../components/eventProperties/EventInfo'
import {EventStatusDropDown} from '../components/eventProperties/EventStatus'
import {ParameterChangeStatusDropDown} from '../components/eventProperties/ParameterChangeStatusDropDown'
import {WorkOrderStatusDropDown} from '../components/eventProperties/WorkOrderEventStatus'
import {regularSize} from '../consts'
import {EventActionList} from '../containers/shiftEvents/EventActionList'
import {useEventUpdateStatus} from '../hooks/api/useEventUpdateStatus'
import {useConfig} from '../hooks/useConfig'
import {ShiftEvent, Assignees, BaseEvent, WorkOrderEventStatus} from '../types/shiftHandover.types'
import {formatDateTime} from '../utils'

export type Props = {
  item: ShiftEvent
  onSapNotificationCreated(): void
  doClose(): void
  doStoppageSplit(item: Partial<ShiftEvent>): void
  doEdit(item: ShiftEvent): void
  disableSap?: boolean
  isSeriesPreview?: boolean
  freeSearchText?: string
  children?: React.ReactNode
}

const EventHighlightLabel: React.FC<{value?: boolean; 'data-test-id': string}> = ({
  value,
  'data-test-id': dataTestId
}) => {
  return value ? <StarBorderIcon data-test-id={dataTestId} /> : null
}

function getUserValues<T>(item: ShiftEvent, t: TFunction, key: string): string | null {
  const assignees: T[] = cloneDeep(get(item, key))
  if (!assignees || assignees.length < 1) return null
  if (assignees.length === 1) {
    return assignees[0]['name']
  }
  const last = assignees.pop()
  return map(assignees, 'name').join(', ') + ` ${t('and')} ` + last?.['name']
}

const DueDateLabel: React.FC<{item: ShiftEvent}> = ({item}) => {
  const {timezone, shifts} = useConfig()
  if (isTask(item) && item.dueDate) {
    const dueDateShift = getShift(item.dueDate, shifts)

    if (item.doNotStartBefore) {
      const endShift = getShift(item.doNotStartBefore, shifts)
      return (
        <>
          <DateFormat date={endShift.startDate} timezone={timezone} /> -{' '}
          <DateFormat date={dueDateShift.startDate} timezone={timezone} />
        </>
      )
    }

    return (
      <>
        <DateFormat date={dueDateShift.startDate} timezone={timezone} /> {dueDateShift.name}
      </>
    )
  }
  return null
}

const categoryLabel = (item: ShiftEvent, t: TFunction) =>
  item.category ? t(`shiftEvent.category.${item.category}`) : t('shiftEvent.action.noneOfThat')

const getEventTile = (title: string, freeSearchText?: string) =>
  freeSearchText ? getHighlightedTitle(title, freeSearchText) : title

// eslint-disable-next-line complexity
export const EventDetails: React.FC<Props> = ({
  item,
  doClose,
  freeSearchText,
  isSeriesPreview,
  children,
  ...rest
}) => {
  const {t} = useTranslation()
  const {timezone, plantId} = useConfig()
  const {kilnStoppageCodes, rawMillStoppageCodes, finishMillStoppageCodes} = useConfig()
  const updateAction =
    useEventUpdateStatus<StatusWithNoneAndCancellationAndApproverAndWorkOrderStates>()

  const [maintenanceNotificationFailed, setMaintenanceNotificationFailed] = useState<boolean>(false)

  const assigneesValue: string | null = useMemo(
    () => getUserValues<Assignees>(item, t, 'assignees'),
    [item, t]
  )
  const approversValue: string | null = useMemo(
    () => getUserValues<User[]>(item, t, 'parameterChange.approvers'),
    [item, t]
  )

  const getStatusField = useCallback(() => {
    if (isWorkOrderStatus(item.status)) {
      return (
        <WorkOrderStatusDropDown
          disabled
          onChange={() => {
            // disabled/impossible
          }}
          data-test-id="event-detail-work-order-status"
          value={item.status as WorkOrderEventStatus}
        />
      )
    }
    if (isStoppage(item)) {
      return (
        <Tag labelKey="shiftEvent.label.status" data-test-id="event-detail-status">
          {item.status && <EventStatusLabel status={item.status} />}
        </Tag>
      )
    }
    if (isParameterChange(item)) {
      return (
        <ParameterChangeStatusDropDown
          isEditMode={true}
          eventData={item}
          data-test-id="event-detail-status"
          onChange={(value) => {
            updateAction.mutate({task: item, status: value})
          }}
        />
      )
    }
    if (isTask(item) && isSeriesPreview) {
      return (
        <EventStatusDropDown
          data-test-id="event-detail-status"
          disabled={true}
          onChange={noop}
          value={item.status}
        />
      )
    }
    return (
      <EventStatusDropDown
        allow={isInformation(item as BaseEvent) || isIdea(item as BaseEvent) ? 'none' : 'cancelled'}
        data-test-id="event-detail-status"
        onChange={(value) => {
          updateAction.mutate({task: item, status: value})
        }}
        value={item.status}
      />
    )
  }, [item, isSeriesPreview, updateAction])

  return (
    <ContentBox
      data-test-id="events-details"
      mode="max100PercentOfParentHeight"
      bodyWithPadding
      title={
        <Typography
          component={'span'}
          variant={'h2'}
          sx={{textTransform: 'none'}}
          data-test-id="event-detail-title"
          display="flex"
          alignItems="center"
        >
          <EventHighlightLabel
            value={item.isHighlighted}
            data-test-id="event-detail-title-highlight"
          />
          {getEventTile(item.title, freeSearchText)}
        </Typography>
      }
      afterTitle={
        <Box display="flex" alignItems="center">
          <EventActionList
            {...rest}
            onlyDeleteSeries={isSeriesPreview}
            item={item}
            doClose={doClose}
            maintenanceNotificationFailed={maintenanceNotificationFailed}
            setMaintenanceNotificationFailed={setMaintenanceNotificationFailed}
          />
          <Typography
            sx={{
              p: 1.75,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
          >
            <CloseIcon
              onClick={() => doClose()}
              color="primary"
              data-test-id="events-details-close"
            />
          </Typography>
        </Box>
      }
      headerSx={{paddingBottom: 0}}
    >
      <EventInfo item={item} sapNotificationFailed={maintenanceNotificationFailed} />

      <Grid
        container
        sx={{
          my: 1.5
        }}
        spacing={2}
      >
        <Grid item {...regularSize}>
          <Tag labelKey="shiftEvent.label.eventType" data-test-id="event-detail-eventType">
            <EventTypeLabel eventType={item.eventType} />
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          {getStatusField()}
        </Grid>
        <Grid item {...regularSize}>
          <Tag labelKey="shiftEvent.label.category" data-test-id="event-detail-category">
            {categoryLabel(item, t)}
          </Tag>
        </Grid>
        {!isTask(item) && (
          <Grid item {...regularSize} data-test-id="event-detail-priority">
            <Tag labelKey="shiftEvent.label.priority">
              {!item.priority ? (
                t('shiftEvent.action.noneOfThat')
              ) : (
                <PriorityTitle priority={item.priority} />
              )}
            </Tag>
          </Grid>
        )}
        <Grid item {...regularSize}>
          <Tag labelKey="shiftEvent.label.processStage" data-test-id="event-detail-processStage">
            {!item.processStage ? (
              t('shiftEvent.action.noneOfThat')
            ) : (
              <EventProcessStageTitle plantId={plantId} stage={item.processStage} />
            )}
          </Tag>
        </Grid>
        <Grid item xs={12}>
          <Tag data-test-id="event-detail-main-equipment" labelKey="shiftEvent.label.mainEquipment">
            {getEquipmentLabelDetails(item.mainEquipment, false)}
          </Tag>
        </Grid>
        <Grid item xs={12}>
          <Tag data-test-id="event-detail-equipment" labelKey="shiftEvent.label.equipment">
            {getEquipmentLabelDetails(item.equipment)}
          </Tag>
        </Grid>
        {!isTask(item) && !isStoppage(item) && !!item.schedule && (
          <Grid item xs={12}>
            <Tag data-test-id="event-detail-schedule" labelKey="shiftEvent.label.schedule">
              {moment(item.schedule.startDate).isSame(item.schedule.endDate, 'day') ? (
                formatDateTime(item.schedule.startDate)
              ) : (
                <>
                  <DateFormat date={item.schedule.startDate} timezone={timezone} />
                  {item.schedule.endDate && (
                    <>
                      {' '}
                      - <DateFormat date={item.schedule.endDate} timezone={timezone} />
                    </>
                  )}
                </>
              )}
            </Tag>
          </Grid>
        )}
        {isTask(item) && (
          <>
            <Grid item {...regularSize}>
              <Tag labelKey="shiftEvent.label.dueDate" data-test-id="event-detail-dueDate">
                <DueDateLabel item={item} />
              </Tag>
            </Grid>

            <Grid item {...regularSize}>
              <Tag labelKey="shiftEvent.label.priority" data-test-id="event-detail-priority">
                {item.priority}
              </Tag>
            </Grid>

            <Grid item {...regularSize}>
              <Tag labelKey="shiftEvent.label.assignee" data-test-id="event-detail-assigneeName">
                {assigneesValue}
              </Tag>
            </Grid>

            {item.repetitionInfo && (
              <Grid item xs={12}>
                <Tag labelKey="shiftEvent.label.recurring">
                  <TaskRepetitionLabel
                    settings={item.repetitionInfo}
                    defaultStartDate={item.dueDate}
                    omitFirstOccurrence
                    plantId={plantId}
                    timezone={timezone}
                  />
                </Tag>
              </Grid>
            )}
          </>
        )}
      </Grid>

      <Grid
        container
        sx={{
          my: 1.5
        }}
        spacing={2}
      >
        {isStoppage(item) && (
          <>
            <Grid item xs={12}>
              {isStoppageTypeWithReason(item.stoppageType) && 'stoppageReason' in item && (
                <Tag
                  labelKey="shiftEvent.label.stoppageReason"
                  data-test-id="event-detail-stoppageReason"
                >
                  {item.stoppageReason}
                </Tag>
              )}
              {isStoppageTypeWithCode(item.stoppageType) && 'stoppageCode' in item && (
                <Tag
                  labelKey="shiftEvent.label.stoppageCode"
                  data-test-id="event-detail-stoppageCode"
                >
                  {item.stoppageCode}{' '}
                  {getStoppageDescription(item, {
                    kiln: kilnStoppageCodes,
                    cementMill: finishMillStoppageCodes,
                    rawMill: rawMillStoppageCodes
                  })}
                </Tag>
              )}
            </Grid>
            <Grid item xs={6}>
              <Tag
                labelKey="shiftEvent.label.stoppageStart"
                data-test-id="event-detail-stoppageStart"
              >
                {formatDateTime(item.stoppageStart)}
              </Tag>
            </Grid>
            <Grid item xs={6}>
              <Tag labelKey="shiftEvent.label.stoppageEnd" data-test-id="event-detail-stoppageEnd">
                {formatDateTime(item.stoppageEnd)}
              </Tag>
            </Grid>
          </>
        )}

        {isParameterChange(item) && (
          <>
            <Grid item xs={12}>
              <Tag
                labelKey="shiftEvent.label.currentValue"
                data-test-id="event-detail-parameter-current"
              >
                {item.parameterChange?.currentValue}
              </Tag>
            </Grid>
            <Grid item xs={12}>
              <Tag labelKey="shiftEvent.label.newValue" data-test-id="event-detail-parameter-new">
                {item.parameterChange?.newValue}
              </Tag>
            </Grid>
            <Grid item xs={12}>
              <Tag
                labelKey="shiftEvent.label.approvers"
                label={t('shiftEvent.label.approver', {count: 0})}
                data-test-id="event-detail-approverName"
              >
                {approversValue}
              </Tag>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Tag
            labelKey="shiftEvent.label.description"
            descriptionSx={{fontWeight: 400, fontSize: 14}}
            data-test-id="event-detail-description"
          >
            {item.description && (
              <MarkdownText options={generateMarkdownOptions(freeSearchText)}>
                {freeSearchText
                  ? getHighlightedTitleWithLink(item.description, freeSearchText, true)
                  : item.description}
              </MarkdownText>
            )}
          </Tag>
        </Grid>

        {isStoppage(item) && item.rootCauseFailureAnalyses && (
          <Grid item xs={12}>
            <Tag
              labelKey="shiftEvent.label.rootCauseFailureAnalyses"
              descriptionSx={{fontWeight: 400, fontSize: 14}}
              data-test-id="event-detail-rootCauseFailureAnalyses"
            >
              <Box display="flex" flexDirection="column">
                {item.rootCauseFailureAnalyses.map(
                  ({title, id}: Partial<RootCauseFailureAnalyses>) => (
                    <Link
                      key={id}
                      href={getMaintainAnalysisUrl(plantId, id)}
                      underline="none"
                      fontWeight={600}
                      width="fit-content"
                    >
                      {title}
                    </Link>
                  )
                )}
              </Box>
            </Tag>
          </Grid>
        )}
      </Grid>
      {children}
    </ContentBox>
  )
}
