import {BarForChart, BarForChartProps, RcfaLinks} from '@hconnect/common/components/runningTimes'
import {DateRange, EquipmentData, RunningTime, RunningTimeStoppage} from '@hconnect/common/types'
import {
  getEventStoppageType,
  getRunningTimeStoppageLabel,
  getStoppageDate
} from '@hconnect/common/utils'
import {Moment} from 'moment'
import moment from 'moment-timezone'
import React, {FC, useMemo} from 'react'

import {useConfig} from '../../hooks/useConfig'

function includeGapsInRunningTimes(dateRange: DateRange, data: RunningTime[]) {
  const runningTimesWithGaps: RunningTime[] = []
  if (data.length === 0) {
    return []
  }
  if (!dateRange.startDate.isSame(data[0].begin)) {
    const beginElement: RunningTime = {
      begin: dateRange.startDate,
      end: data[0].begin,
      runningTimeType: undefined,
      stoppageType: data[0].stoppageType
    }

    runningTimesWithGaps.push(beginElement)
  }

  data.forEach((rt, index) => {
    runningTimesWithGaps.push(rt)
    if (data[index + 1] && !moment(rt.end).isSame(moment(data[index + 1].begin))) {
      runningTimesWithGaps.push({
        begin: rt.end,
        end: data[index + 1].begin,
        runningTimeType: undefined,
        stoppageType: rt.stoppageType
      })
    }
  })

  if (!dateRange.endDate.isSame(data[data.length - 1].end)) {
    const endElement: RunningTime = {
      begin: data[data.length - 1].end,
      end: dateRange.endDate,
      runningTimeType: undefined,
      stoppageType: data[data.length - 1].stoppageType
    }

    runningTimesWithGaps.push(endElement)
  }

  return runningTimesWithGaps
}

function getBarPercentage(
  timeStart: Moment | undefined,
  timeEnd: Moment | undefined,
  totalRunningTime: number
) {
  if (!timeStart || !timeEnd || timeStart === timeEnd) {
    return '0%'
  }

  return ((timeEnd.diff(timeStart, 'minutes') / totalRunningTime) * 100).toString() + '%'
}

export interface Props {
  dateRange: DateRange
  data: RunningTime[]
  handleStoppageClick: (rt: RunningTime, stoppage?: RunningTimeStoppage) => void | Promise<void>
  handleRunningClick?: (rt: RunningTime) => void
  equipment?: EquipmentData
}

export const StackedBarChart: FC<Props> = ({
  dateRange,
  data,
  handleStoppageClick,
  handleRunningClick,
  equipment
}) => {
  const {kilnStoppageCodes, rawMillStoppageCodes, finishMillStoppageCodes, plantId, timezone} =
    useConfig()

  const totalRunningTime = useMemo(() => {
    return dateRange.endDate.diff(dateRange.startDate, 'minutes')
  }, [dateRange])

  const runningTimesWithGapsArray = useMemo(
    () => includeGapsInRunningTimes(dateRange, data),
    [dateRange, data]
  )

  return (
    <>
      {runningTimesWithGapsArray.map((rt, index) => {
        const barForChartProps: BarForChartProps = {
          timezone,
          percentage: getBarPercentage(rt.begin, rt.end, totalRunningTime),
          runningTime: rt,
          handleRunningClick: handleRunningClick ? () => handleRunningClick(rt) : undefined
        }

        if (rt.stoppages) {
          return rt.stoppages.map((stoppage, stoppageIndex) => {
            const {from, to} = getStoppageDate(stoppage, rt)
            return (
              <BarForChart
                key={`${index}-${stoppageIndex}`}
                {...barForChartProps}
                stoppageIndex={stoppageIndex}
                percentage={getBarPercentage(from, to, totalRunningTime)}
                handleStoppageClick={() => void handleStoppageClick(rt, stoppage)}
                tooltip={{
                  header:
                    equipment?.equipmentCategory &&
                    getRunningTimeStoppageLabel(
                      {
                        kiln: kilnStoppageCodes,
                        cementMill: finishMillStoppageCodes,
                        rawMill: rawMillStoppageCodes
                      },
                      stoppage,
                      getEventStoppageType(equipment)
                    ),
                  footer: rt.rootCauseFailureAnalyses && (
                    <RcfaLinks
                      plantId={plantId}
                      rootCauseFailureAnalyses={rt.rootCauseFailureAnalyses}
                    />
                  )
                }}
              />
            )
          })
        }
        return (
          <BarForChart
            key={index}
            {...barForChartProps}
            handleStoppageClick={() => void handleStoppageClick(rt)}
            tooltip={{
              footer: rt.rootCauseFailureAnalyses && (
                <RcfaLinks
                  plantId={plantId}
                  rootCauseFailureAnalyses={rt.rootCauseFailureAnalyses}
                />
              )
            }}
          />
        )
      })}
    </>
  )
}
